import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import MarkImg from "../images/symbol.svg";
import WordmarkImg from "../images/wordmark.svg";
import ogImg from "../images/ogimage.png";

import Lottie from "react-lottie";
import * as animationData from "../images/data.json";

import { motion } from "framer-motion";
import Obfuscate from "react-obfuscate";

const lg = "684px";

const Mark = styled.img`
  width: 56px;
  margin-right: 16px;
`;
const Wordmark = styled.img`
  width: 80px;
  height: 25px;
  margin-top: 4px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
`;
const Grid = styled.div`
  display: block;
  padding: 2rem;
  position: relative;
  z-index: 999;
  div {
    display: flex;
    flex-direction: column;
    height: auto;
    // height: 50vh;
    @media (min-width: ${lg}) {
      height: calc(100vh - 6rem);
    }
  }
  main {
    margin-top: auto;
  }
  @media (min-width: ${lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    padding: 3rem;
  }
`;

const Title = styled.h1`
  margin: 4rem 0 1rem 0;
  font-weight: 500;
  line-height: 1.125;
  font-size: 6vw;
  @media (min-width: ${lg}) {
    font-size: 2.7vw;
    margin: 0 0 1rem 0;
    // max-width: 400px;
  }
`;

const Para = styled.p`
  margin: 1rem 0 2rem 0;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  max-width: 580px;
  color: #c6c6c5;
`;

const GetInTouch = styled.div`
  a {
    direction: ltr !important;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.175rem;
    margin-bottom: 2rem;
    span {
      transition: transform 0.2s ease-in-out;
      display: inline-block;
      transform: translateX(0);
    }
    &:hover {
      span {
        transform: translateX(5px);
      }
    }
  }
`;

const Main = styled.main`
  div:nth-child(1) {
    margin-top: auto;
    height: auto;
  }
  div:nth-child(2) {
    height: auto;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  align-items: center;
  opacity: 1;
  height: 100%;
  width: 100%;
`;
const loadingDelay = 2.5;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const IndexPage = () => (
  <Layout>
    <SEO
      title="Transformative data technology"
      description="PortalX transforms conventional enterprise data into next-gen data. Through its classified set of technologies, data is pseudonymized, secured with next-generation hardware-accelerated encryption, enriched with Autonomous Data Governance policy contracts as well as PortalX’s first-ever ad-hoc anonymization layer, and stored in your organization’s hybrid-cloud environment for immediate use."
      image={"http://theportalx.com" + ogImg}
    />
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ duration: 1, delay: loadingDelay }}
      layout
    >
      <LoadingContainer>
        <Lottie options={defaultOptions} height={120} width={120} />
      </LoadingContainer>
    </motion.div>

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2, delay: loadingDelay + 1 }}
      layout
    >
      <Grid>
        <div>
          <motion.div
            initial={{ opacity: 0, y: "40px" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: loadingDelay + 1.75 }}
            layout
          >
            <Header>
              <Mark src={MarkImg} alt="PortalX Logo" />
              <Wordmark src={WordmarkImg} alt="PoralX Wordmark" />
            </Header>
          </motion.div>
          <Main>
            <motion.div
              initial={{ opacity: 0, y: "40px" }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: loadingDelay + 2 }}
            >
              <Title>
                Portal is a next-generation data platform that enables everyone
                in your organization to find, access, and use all of your
                organization’s data.
              </Title>
              <Para>
                Break open data silos and unify data from any system and any
                jurisdiction. Create sample stores and central data repositories
                to help everyone find and access all of the data they need. Spin
                up virtual workspaces and collaborate effortlessly with anyone
                inside and outside of your organization. Use all of your
                organization's data without having to worry about data quality,
                data compliance, or data governance ever again.
              </Para>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: "40px" }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: loadingDelay + 2.5 }}
            >
              <GetInTouch>
                <Obfuscate
                  email="info@theportalx.com"
                  headers={{
                    subject: "Hello!",
                  }}
                >
                  GET IN TOUCH <span>→</span>
                </Obfuscate>
              </GetInTouch>
            </motion.div>
          </Main>
        </div>
        <div>
          <motion.div
            initial={{ opacity: 0, y: "40px" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: loadingDelay + 2.5 }}
          >
            <Image />
          </motion.div>
        </div>
      </Grid>
    </motion.div>
  </Layout>
);

export default IndexPage;
